<template>
  <div class="exhibition-2-child instruments">
    <section
      ref="sectionWrapper"
      :class="{
        'section-wrapper': true,
        'pointer-events-none': !dimmed,
        'fade-out': !dimmed || openDialog,
      }"
    >
      <div class="scroll-section section-intro">
        <div class="section-title">
          <img
            src="@/assets/images/exhibition-2-title@2x.png"
            class="title"
            alt="聽繪音聲 LABORATORIUM"
            width="100%"
            height="auto"
          />
        </div>
        <div class="section-panel-text">
          <p>
            音樂連繫文化，簡單至一個音色，所蘊含的內涵不比一首歌謠少。弦線震動是物理；聲音穿梭空間是建築；節奏律動是人與另一世界溝通的中介。音與聲，人與自然本來就是密不可分。透過樂器，我們接觸到的不是音樂，而是世界。
          </p>
          <p>
            本裝置主要呈現三組展品，包括中亞的一些傳統樂器，中亞的遊牧與定居的音樂文化之間的對比，和中亞音樂的圖像誌(iconography)。
          </p>
          <p>
            遊牧文化的樂器不少是和薩滿通靈儀式有關，樂器演奏會模仿大自然裡的一些聲音，而遊牧民族也認為一些樂器本身是會「說話」的，具有與人的情緒、心境進行溝通的能力。定居文化的音樂以小組合奏為多，發展出來的音樂比較精緻、細膩。例如河中流域的三個汗國布哈拉、希瓦、浩罕各自發展出自己傳統風格特色的木卡姆。木卡姆屬於中亞古典文學遺產的一部分，其套曲裡有許多古代偉大詩人（如魯米、海菲茲）的詩。
          </p>
          <!-- <p>
            中亞的遊牧與定居的音樂文化之間的對比表，令觀眾更直觀感受這兩種音樂文化的一些差異。
          </p>
          <p>關於中亞音樂的幾幅圖像誌（iconography），呈現了不同的音樂場景。</p> -->
        </div>
        <div class="btn-controls-vertical">
          <div
            class="btn-next btn-vertical btn-next-vertical position-relative"
            @click="gotoMenu"
          >
            <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
          </div>
        </div>
        <div
          ref="spacer"
          :class="{
            'section-content-spacer': true,
            'spacer-not-ready': !videoIsPlaying,
          }"
        ></div>
      </div>
    </section>
    <div
      :class="{
        'btn-controls': true,
        'fade-out': dimmed,
        'pointer-events-none': dimmed,
      }"
    >
      <transition name="fade">
        <div
          v-show="currentMarker != 3 || !transitioning"
          :class="{ 'btn-prev': true, 'btn-controls-disable': controlsDisable }"
          @click="prevFace"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
      </transition>
      <transition name="fade">
        <div
          v-show="currentMarker != 1 || !transitioning"
          :class="{ 'btn-next': true, 'btn-controls-disable': controlsDisable }"
          @click="nextFace"
        >
          <inline-svg :src="require(`@/assets/images/arrow-lg.svg`)" />
        </div>
      </transition>
    </div>
    <div class="inst-modal-wrapper">
      <div class="inst-modal-container">
        <div class="inst-modal-content-container">
          <div class="btn-close" @click="hideModal">
            <v-icon>mdi-close</v-icon>
          </div>
          <div class="inst-modal-content">
            <div class="inst-image">
              <transition name="fade">
                <v-img
                  ref="instImage"
                  @load="imageIsLoaded"
                  :src="
                    require(`@/assets/images/gallery2/instruments-modal/inst-modal-${selected +
                      1}.png`)
                  "
                  width="100%"
                  height="auto"
                />
              </transition>
            </div>
            <h3 class="inst-name" v-html="insts[selected].name"></h3>
            <p class="inst-content" v-html="insts[selected].content"></p>
          </div>
        </div>
      </div>
      <div class="modal-bg"></div>
    </div>
    <div :class="{ 'inst-container': true, dimmed: dimmed }">
      <div class="inst-group-1 inst-group">
        <div v-for="i in 8" :key="i" class="inst-item" @click="clickItem(i)">
          <img
            :src="require(`@/assets/images/gallery2/instruments/inst-${i}.png`)"
            width="100%"
            height="auto"
            alt=""
          />
        </div>
      </div>
      <div class="inst-group-2 inst-group">
        <div
          v-for="i in 5"
          :key="i"
          class="inst-item"
          @click="clickItem(i + 8)"
        >
          <img
            :src="
              require(`@/assets/images/gallery2/instruments/inst-${i + 8}.png`)
            "
            width="100%"
            height="auto"
            alt=""
          />
        </div>
      </div>
      <div class="inst-group-3 inst-group">
        <div
          v-for="i in 5"
          :key="i"
          class="inst-item"
          @click="clickItem(i + 13)"
        >
          <img
            :src="
              require(`@/assets/images/gallery2/instruments/inst-${i + 13}.png`)
            "
            width="100%"
            height="auto"
            alt=""
          />
        </div>
      </div>
      <div class="inst-player">
        <vue-plyr ref="plyr" :options="options">
          <video crossorigin playsinline>
            <source size="100%" src="/exhibition-2-nav.mp4" type="video/mp4" />
          </video>
        </vue-plyr>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl, Howler } from "howler";
import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default {
  data() {
    return {
      transitioning: true,
      imageLoaded: true,
      videoIsPlaying: false,
      myBase: null,
      players: {
        opening: null,
        loop: null,
      },
      myBaseVolume: {
        max: 0.7,
        min: 0.2,
      },
      myInst: null,
      myInstPlayer: null,
      volume: 1,
      controlsDisable: false,
      group: 1, // for CSS classes name
      groupId: 0, // for array Id
      currentGroupId: 0, // will not change until reach next face end
      currentFace: 0,
      currentMarker: 0,
      marker: [
        { face: 0, groupId: 0, group: 1, length: [2.8, 3.3] },
        { face: 1, groupId: 2, group: 3, length: [5.3, 5.8] },
        { face: 0, groupId: 0, group: 1, length: [8.3, 8.8] },
        { face: -1, groupId: 1, group: 2, length: [10.8, 11.3] },
      ],
      selected: 0,
      selectedSection: 0,
      openDialog: false,
      options: {
        autoplay: true,
        controls: false,
      },
      currentTime: "",
      myPlayer: null,
      tlGroups: [null, null, null],
      insts: [
        {
          name: "彈撥爾",
          content:
            "長頸的有品彈撥樂器，通常用於烏茲別克-塔吉克和維吾爾的傳統古典音樂上。演奏時撥弄其中一條弦，其他弦則作為共鳴弦。",
        },
        {
          name: "都塔爾",
          content:
            "都塔爾在波斯文裡是「兩根弦」的意思。它是長頸的撥弦樂器，通常用桑木或杏木製成，自伊朗東部到中國新疆都有不同的形制。演奏時直接用手指同時彈撥兩根弦。都塔爾可用來演奏古典音樂或宗教音樂，也可為說唱音樂伴奏、演奏民間音樂。",
        },
        {
          name: "朵以拉手鼓",
          content:
            "流行於中亞定居（非遊牧）地區的樂器，面為羊皮覆蓋，鼓框內部有金屬環，產生如鈴鼓的效果。",
        },
        {
          name: "熱瓦甫",
          content:
            "流行於中亞的橫抱式彈撥樂器，琴身木製，共鳴箱成半球形，琴面蒙以動物皮，通常以最外一弦演奏旋律，其餘為共鳴弦。",
        },
        {
          name: "艾捷克",
          content:
            "琴身圓形的短頸無品拉弦樂器，通常有三或四條弦。由伊朗的卡曼徹演變而來。流行於烏茲別克、塔吉克、土庫曼和新疆維吾爾等地。",
        },
        {
          name: "薩塔爾",
          content:
            "維吾爾傳統的長頸拉弦樂器，外型與彈撥爾相似，有品，具9-17條共鳴弦。對於演奏當地的木卡姆來說是很重要的樂器。",
        },
        {
          name: "薩多",
          content:
            "古老的中亞樂器，與彈撥爾外型相似，琴身稍大，有四根主奏弦和四根共鳴弦，以拉弦或彈撥的方式演奏。用於烏茲別克古典音樂的獨奏表演。",
        },
        {
          name: "嗩吶",
          content:
            "屬於雙簧管樂器，音色明亮，音量大，通常會在傳統的婚禮、喪禮和其他戶外節慶當中演奏。誕生于阿契美尼德時期，在西亞、中亞、歐洲東南部、北非以及中國一些地方的民間音樂傳統上都很流行。",
        },
        {
          name: "冬不拉",
          content:
            "哈薩克的代表樂器，以前哈薩克牧民的氈房裡通常都會懸掛一把冬不拉。他們認為冬不拉是能「說話」、「表達」的樂器。冬不拉有品、長頸，有兩條羊腸弦或絲弦，以掃弦或彈撥的方式演奏。哈薩克「阿肯」的伴唱樂器就是冬不拉。",
        },
        {
          name: "口弦琴",
          content:
            "流行於亞洲內陸牧民之間的傳統樂器，木製或金屬製。口簧含在雙唇間，撥動簧片，以演奏者的口腔共鳴產生樂音，可以通過改變嘴唇和口腔形狀發出不同聲音。在許多文化裡都認為口弦琴具有魔法或靈性。口弦琴樂曲多表達與動物、大自然和遊牧生活相關的主題，以前在婦女和兒童之間很流行。",
        },
        {
          name: "庫布孜",
          content:
            "用一塊木頭挖空而成的兩弦拉弦樂器，流行於哈薩克。早期的庫布孜被認為有守護的力量，可以作為薩滿和靈溝通的一個中介。通過薩滿儀式，薩滿邊唱邊演奏庫布孜，有治療的作用，也可以保護社群，或者也可以是史詩的一個載體。傳統上其特色是利用泛音和擬聲，模擬圖騰的動物聲音、形態、動作，這些會被認為是神靈的語言。",
        },
        {
          name: "色布孜格",
          content:
            "直吹牧笛，是哈薩克主要的吹管樂器，被哈薩克人譽為「心笛」。吹奏時用舌頭堵住大部份管口，留小部份為吹孔，喉嚨也持續發出低音，和笛聲形成雙聲複音。色布孜格音色柔和，音量偏小，適合抒情的樂曲。",
        },
        {
          name: "杰特根",
          content:
            "哈薩克的箏，是由古代中國傳入的樂器。由於需要適應遊牧生活而體積較小，直接放在大腿上彈奏。杰特根只有七根弦，琴碼為七枚羊踝骨，靠左右移動琴碼來調音。",
        },
        {
          name: "火不思",
          content:
            "吉爾吉斯最有代表性的傳統樂器，有三條弦，琴身多使用杏樹、梨樹或榆樹的木材製成，形狀似梨，琴桿沒有品。用指甲彈撥、敲擊或掃琴弦來演奏，有時也會使用一些手部和手臂的動作來增加故事性和技巧性。",
        },
        {
          name: "薩滿鼓",
          content: "幫助薩滿進入薩滿意識狀態的樂器/工具。",
        },
        {
          name: "響鈴",
          content: "薩滿巫師進行儀式時使用的樂器之一，具有驅靈的作用。",
        },
        {
          name: "振鈴",
          content: "薩滿巫師進行儀式時使用的樂器之一，具有驅靈的作用。",
        },
        {
          name: "馬蹄石",
          content: "可擊出像馬或駱駝奔跑時的節奏。",
        },
      ],
    };
  },
  destroyed() {
    this.myInst?.once("fade", () => {
      this.myInst.unload();
    });
    this.myInst?.fade(this.myInst.volume(), 0, 1000);

    this.myBase?.once("fade", () => {
      this.myBase.unload();
    });
    this.myBase?.fade(this.myBase.volume(), 0, 1000);

    this.group = 1;
    this.groupId = 0;
    this.currentMarker = 0;
  },
  computed: {
    videoAutoPlay() {
      if (this.myPlayer == null) return false;
      if (this.myPlayer.currentTime == 0) {
        return false;
      } else {
        return true;
      }
    },
    dimmed() {
      return this.$store.state.dimmed;
    },
  },
  mounted() {
    var vm = this;
    this.group = 1;
    this.groupId = 0;
    this.currentMarker = 0;
    this.$store.commit("updateCurrentSection", 0);

    document.querySelector("html,body").style.overflow = "auto";

    const tl = new gsap.timeline();
    tl.set(".inst-modal-wrapper", { opacity: 0, display: "none" });

    tl.set([".inst-group", ".inst-group .inst-item"], {
      opacity: 0,
      display: "none",
    });

    this.tlGroups[0] = new gsap.timeline();
    this.tlGroups[1] = new gsap.timeline();
    this.tlGroups[2] = new gsap.timeline();

    this.myBase = new Howl({
      src: ["../../../music/gallery2/opening-loop-R1.mp3"],
      sprite: {
        opening: [0, 93128, false],
        loop: [93128, 169000, true],
      },
    });

    this.myBase.once("load", () => {
      this.players.opening = this.myBase.stop("opening");
      this.myBase.volume(this.myBaseVolume.max);
      this.players.opening = this.myBase.play("opening");
    });

    this.myBase.once(
      "end",
      () => {
        this.players.loop = this.myBase.stop("loop");
        this.myBase.volume(this.myBaseVolume.max);
        this.players.loop = this.myBase.play("loop");
      },
      this.players.opening
    );

    vm.myPlayer = vm.$refs.plyr?.player;

    vm.myPlayer.on("loadeddata", (t) => {
      vm.myPlayer.play();
    });

    this.myPlayer.on("timeupdate", (t) => {
      this.currentTime = vm.myPlayer.currentTime;
      var currentTime = vm.currentTime;

      if (currentTime < 2.5) {
        vm.myPlayer.play();
      }

      vm.marker.forEach((item, i) => {
        if (currentTime > item.length[0] && currentTime < item.length[1]) {
          vm.currentMarker = i;
          vm.currentFace = item.face;
          vm.group = item.group;
          vm.groupId = item.groupId;
          vm.myPlayer.pause();
          vm.transitioning = false;
          vm.controlsDisable = false;
          if (!this.dimmed) {
            vm.showInst();
          }
        }
      });
    });
    this.myPlayer.once("play", (t) => {
      this.videoIsPlaying = true;
    });
    this.myPlayer.on("ended", (t) => {
      var item = this.marker[0];
      vm.currentMarker = 0;
      vm.currentFace = item.face;
      vm.group = item.group;
      vm.groupId = item.groupId;

      vm.myPlayer.pause();

      vm.transitioning = false;

      vm.controlsDisable = false;
      if (!this.dimmed) {
        vm.showInst();
      }
    });

    // this.$nextTick(() => {
    ScrollTrigger.create({
      trigger: ".section-content-spacer",
      start: "top center",
      end: "bottom top-=500",
      onToggle: (self) => {
        if (vm.myPlayer.currentTime == 0) {
          vm.myPlayer.play();
        }
        if (self.isActive) {
          this.$store.commit("updateDimmed", false);
          // this.dimmed = false;
          this.showInst();
        } else {
          this.$store.commit("updateDimmed", true);
          // this.dimmed = true;
          this.hideInst();
        }
      },
    });
    // });
  },
  methods: {
    gotoMenu() {
      const vm = this;

      if (this.myPlayer.currentTime == 0) {
        this.myPlayer.play();
      }

      gsap.to(window, {
        duration: 2,
        scrollTo: this.$refs.spacer,
        ease: Power2.easeOut,
      });
    },
    prevFace() {
      if (this.currentMarker == 0) {
        this.currentMarker = 2;
      }
      this.$refs.plyr.player.currentTime = this.marker[
        this.currentMarker
      ].length[1];
      this.transitioning = true;
      this.$refs.plyr.player.play();
      this.hideInst();
      this.controlsDisable = true;
    },
    nextFace() {
      this.$refs.plyr.player.currentTime = this.marker[
        this.currentMarker
      ].length[1];
      this.transitioning = true;
      this.hideInst();
      this.$refs.plyr.player.play();
      this.controlsDisable = true;
    },
    showInst() {
      var vm = this;
      this.tlGroups[this.groupId].to(`.inst-group-${this.group}`, {
        duration: 0.5,
        opacity: 1,
        ease: Power2.easeOut,
        display: "block",
      });

      this.tlGroups[this.groupId].to(`.inst-group-${this.group} .inst-item`, {
        duration: 0.5,
        opacity: 1,
        display: "block",
        ease: Power2.easeOut,
        stagger: 0.1,
      });
    },
    hideInst() {
      var vm = this;
      // this.tlGroups[this.groupId]?.reverse();
      this.tlGroups[this.groupId].to(`.inst-group-${this.group} .inst-item`, {
        duration: 0.5,
        opacity: 0,
        display: "none",
        ease: Power2.easeOut,
        stagger: { each: 0.1, ease: Power2.easeIn },
      });
    },
    clickItem(i) {
      if (this.controlsDisable) return;
      this.selected = i - 1;
      this.showModal();

      this.myInst?.unload();
      this.myInst = new Howl({
        src: [`../../music/gallery2/instruments/inst-${i}.mp3`],
      });
      this.myInst.once("load", () => {
        this.myInst.stop();
        this.myInst.volume(1);
        this.myInstPlayer = this.myInst.play();
      });
      this.$gtag.event("Click on Instrument", {
        event_category: "樂器",
        event_label: `${i} - ${this.insts[this.selected].name}`,
      });

      this.myBase?.fade(this.myBase.volume(), this.myBaseVolume.min, 1500);
    },

    showModal() {
      this.$store.commit("updateDimmed", true);
      // this.dimmed = true;
      this.openDialog = true;
      document.querySelector("html,body").style.overflow = "hidden";
      const tl = new gsap.timeline();
      tl.set(".inst-modal-wrapper .inst-image", { opacity: 0 });
      tl.to(".inst-modal-wrapper", {
        duration: 0.5,
        opacity: 1,
        display: "flex",
        ease: Power2.easeOut,
      });

      tl.to(
        ".inst-modal-wrapper .inst-image",
        {
          duration: 0.5,
          opacity: 1,
          ease: Power2.easeOut,
        },
        ".5"
      );
    },
    hideModal() {
      const tl = new gsap.timeline();
      tl.to(".inst-modal-wrapper", {
        duration: 1,
        opacity: 0,
        display: "none",
        ease: Power2.easeOut,
      });
      this.myInst.once("fade", () => {
        this.myInst.unload();
        // }
        this.imageLoaded = false;
        this.$store.commit("updateDimmed", false);
        // this.dimmed = false;

        this.openDialog = false;
        document.querySelector("html,body").style.overflow = "auto";
      });
      this.myInst.fade(this.myInst.volume(), 0, 1000);
      this.myBase?.fade(this.myBase.volume(), this.myBaseVolume.max, 1500);
    },
    imageIsLoaded() {
      this.imageLoaded = true;
      const tl = new gsap.timeline();
      tl.set(this.$refs.instImage, { opacity: 0 });
      tl.to(this.$refs.instImage, {
        opacity: 1,
        duration: 0.5,
        ease: Power2.easeOut,
      });
    },
  },
};
</script>

<style lang="scss"></style>
