var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exhibition-2-child instruments"},[_c('section',{ref:"sectionWrapper",class:{
      'section-wrapper': true,
      'pointer-events-none': !_vm.dimmed,
      'fade-out': !_vm.dimmed || _vm.openDialog,
    }},[_c('div',{staticClass:"scroll-section section-intro"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"btn-controls-vertical"},[_c('div',{staticClass:"btn-next btn-vertical btn-next-vertical position-relative",on:{"click":_vm.gotoMenu}},[_c('inline-svg',{attrs:{"src":require("@/assets/images/arrow-lg.svg")}})],1)]),_c('div',{ref:"spacer",class:{
          'section-content-spacer': true,
          'spacer-not-ready': !_vm.videoIsPlaying,
        }})])]),_c('div',{class:{
      'btn-controls': true,
      'fade-out': _vm.dimmed,
      'pointer-events-none': _vm.dimmed,
    }},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentMarker != 3 || !_vm.transitioning),expression:"currentMarker != 3 || !transitioning"}],class:{ 'btn-prev': true, 'btn-controls-disable': _vm.controlsDisable },on:{"click":_vm.prevFace}},[_c('inline-svg',{attrs:{"src":require("@/assets/images/arrow-lg.svg")}})],1)]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentMarker != 1 || !_vm.transitioning),expression:"currentMarker != 1 || !transitioning"}],class:{ 'btn-next': true, 'btn-controls-disable': _vm.controlsDisable },on:{"click":_vm.nextFace}},[_c('inline-svg',{attrs:{"src":require("@/assets/images/arrow-lg.svg")}})],1)])],1),_c('div',{staticClass:"inst-modal-wrapper"},[_c('div',{staticClass:"inst-modal-container"},[_c('div',{staticClass:"inst-modal-content-container"},[_c('div',{staticClass:"btn-close",on:{"click":_vm.hideModal}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{staticClass:"inst-modal-content"},[_c('div',{staticClass:"inst-image"},[_c('transition',{attrs:{"name":"fade"}},[_c('v-img',{ref:"instImage",attrs:{"src":require(("@/assets/images/gallery2/instruments-modal/inst-modal-" + (_vm.selected +
                    1) + ".png")),"width":"100%","height":"auto"},on:{"load":_vm.imageIsLoaded}})],1)],1),_c('h3',{staticClass:"inst-name",domProps:{"innerHTML":_vm._s(_vm.insts[_vm.selected].name)}}),_c('p',{staticClass:"inst-content",domProps:{"innerHTML":_vm._s(_vm.insts[_vm.selected].content)}})])])]),_c('div',{staticClass:"modal-bg"})]),_c('div',{class:{ 'inst-container': true, dimmed: _vm.dimmed }},[_c('div',{staticClass:"inst-group-1 inst-group"},_vm._l((8),function(i){return _c('div',{key:i,staticClass:"inst-item",on:{"click":function($event){return _vm.clickItem(i)}}},[_c('img',{attrs:{"src":require(("@/assets/images/gallery2/instruments/inst-" + i + ".png")),"width":"100%","height":"auto","alt":""}})])}),0),_c('div',{staticClass:"inst-group-2 inst-group"},_vm._l((5),function(i){return _c('div',{key:i,staticClass:"inst-item",on:{"click":function($event){return _vm.clickItem(i + 8)}}},[_c('img',{attrs:{"src":require(("@/assets/images/gallery2/instruments/inst-" + (i + 8) + ".png")),"width":"100%","height":"auto","alt":""}})])}),0),_c('div',{staticClass:"inst-group-3 inst-group"},_vm._l((5),function(i){return _c('div',{key:i,staticClass:"inst-item",on:{"click":function($event){return _vm.clickItem(i + 13)}}},[_c('img',{attrs:{"src":require(("@/assets/images/gallery2/instruments/inst-" + (i + 13) + ".png")),"width":"100%","height":"auto","alt":""}})])}),0),_c('div',{staticClass:"inst-player"},[_c('vue-plyr',{ref:"plyr",attrs:{"options":_vm.options}},[_c('video',{attrs:{"crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"100%","src":"/exhibition-2-nav.mp4","type":"video/mp4"}})])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-title"},[_c('img',{staticClass:"title",attrs:{"src":require("@/assets/images/exhibition-2-title@2x.png"),"alt":"聽繪音聲 LABORATORIUM","width":"100%","height":"auto"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-panel-text"},[_c('p',[_vm._v(" 音樂連繫文化，簡單至一個音色，所蘊含的內涵不比一首歌謠少。弦線震動是物理；聲音穿梭空間是建築；節奏律動是人與另一世界溝通的中介。音與聲，人與自然本來就是密不可分。透過樂器，我們接觸到的不是音樂，而是世界。 ")]),_c('p',[_vm._v(" 本裝置主要呈現三組展品，包括中亞的一些傳統樂器，中亞的遊牧與定居的音樂文化之間的對比，和中亞音樂的圖像誌(iconography)。 ")]),_c('p',[_vm._v(" 遊牧文化的樂器不少是和薩滿通靈儀式有關，樂器演奏會模仿大自然裡的一些聲音，而遊牧民族也認為一些樂器本身是會「說話」的，具有與人的情緒、心境進行溝通的能力。定居文化的音樂以小組合奏為多，發展出來的音樂比較精緻、細膩。例如河中流域的三個汗國布哈拉、希瓦、浩罕各自發展出自己傳統風格特色的木卡姆。木卡姆屬於中亞古典文學遺產的一部分，其套曲裡有許多古代偉大詩人（如魯米、海菲茲）的詩。 ")])])}]

export { render, staticRenderFns }